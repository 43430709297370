<template>
    <modal
        class="thanks-modal"
        :adaptive="true"
        name="thanks-modal"
        height="auto"
        width="400"
    >
        <div class="close-modal" @click="$modal.hide('thanks-modal')">
            <img src="@assets/img/icons/x.svg" alt="X" width="15px">
        </div>
        <div class="content">
            <svg
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="13.332"
                    y="13.333"
                    width="73.3333"
                    height="73.3333"
                    rx="36.6667"
                    fill="#787880"
                    fill-opacity="0.36"
                />
                <path
                    d="M30 48.96L42.96 61.92L68.88 36"
                    stroke="#05A88F"
                    stroke-width="4"
                    stroke-linecap="round"
                />
            </svg>
            <h1>Thanks for reporting</h1>
            <p>We’ll review your report and take action if there is a violation to our Content Creation Policies</p>
            <button
                class="btn btn-primary submit-btn"
                @click="$modal.hide('thanks-modal')"
            >
                Done
            </button>
        </div>
    </modal>
</template>

<script>
export default {
    name: "ThanksModal"
}
</script>

<style lang="scss" scoped>
.thanks-modal {
    z-index: 99999;

    .close-modal {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        z-index: 3;

        @media(max-width: $sm) {
            right: 15px;
            top: 15px;

            img {
                width: 15px;
            }
        }
    }

    /deep/ .vm--modal{
        background-color: #1C1C1E;
        border-radius: 10px;
        box-shadow: none;

        .content {
            padding: 35px 75px;
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                margin-bottom: 25px;
            }

            h1 {
                text-align: center;
                font-weight: 600;
                font-size: 26px;
                line-height: 20px;
                margin-bottom: 25px;
            }

            p {
                text-align: center;
                font-size: 16px;
                color: rgba(235, 235, 245, 0.6);
                margin-bottom: 30px;
            }
        }
    }
}
</style>
